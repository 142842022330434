import React from "react";
import { Link } from "react-router-dom";
import "./DashboardHome.css";

const DashboardHome = () => {
  return (
    <div>
      <h1>Willkommen im UserX Dashboard</h1>
      <p>Wählen Sie eine Aktion:</p>
      <ul>
        <li>
          <Link to="/add-business">Neues Geschäft hinzufügen</Link>
        </li>
        <li>
          <Link to="/manage-businesses">Geschäfte verwalten</Link>
        </li>
        <li>
          <Link to="/reviews">Bewertungen anzeigen</Link>
        </li>
      </ul>
    </div>
  );
};

export default DashboardHome;
