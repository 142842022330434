import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardHome from "./pages/DashboardHome";
import AddBusiness from "./pages/AddBusiness";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<DashboardHome />} /> {/* Startseite */}
          <Route path="/add-business" element={<AddBusiness />} />
          {/* Weitere Routen können hier hinzugefügt werden */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
