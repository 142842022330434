import React, { useState } from "react";
import { postBusiness } from "../api"; // Stelle sicher, dass du die API-Datei erstellst

const AddBusiness = () => {
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    address: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postBusiness(formData); // API-Aufruf
      setMessage("Geschäft erfolgreich hinzugefügt!");
      setFormData({ name: "", category: "", address: "" });
    } catch (error) {
      setMessage("Fehler beim Hinzufügen des Geschäfts.");
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Neues Geschäft hinzufügen</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Kategorie:
          <input
            type="text"
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Adresse:
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Geschäft hinzufügen</button>
      </form>
    </div>
  );
};

export default AddBusiness;
